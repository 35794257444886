.menu-container {
    display: flex;
    background-color: #f9f9f9; 
    padding: 10px; 
}

.menu-item {
    display: flex;
    align-items: center;
    color: #003366;
    margin-right: 20px;
    cursor: pointer; 
    transition: color 0.3s; 
}

.menu-item:hover {
    color: #d66c09; 
}

.menu-item svg {
    margin-right: 5px; 
}

.menu-item.active {
    color: #d66c09; 
}
