.top-header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
}

.logo-image {
    max-width: 70%;
    max-height: 70%;
    animation: pulse 1.5s infinite;
}

/* Hide the logo on screens smaller than 600px */
@media (max-width: 600px) {
    .logo-image {
        display: none;
    }
    .welcome-text {
        display: none;
    }
    .user-info {
        display: none;
    }
}

.icon-avatar {
    background-color: rgb(244 244 246);
    color: #000;
    cursor: pointer;
    font-size: 16px;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.icon-avatar:hover {
    background-color: #e0e0e0;
}

.profile-icon {
    background-color: rgb(244, 228, 228);
    color: white;
    cursor: pointer;
    width: 40px;
    height: 40px;
}


.logo-image {
    height: 40px;
}

